import React, { useState, useEffect, useRef } from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Routes, Route, useLocation, useNavigationType } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Indexes from './pages/index/index';
import Policy from './pages/policy/policy';
import Header from './components/header/header';
import Messages from './components/popups/messages';
import Footer from './components/footer/footer';
import Follow from './components/mouseFollow/follow';

import './components/pageTransitions/pageTransitions.css';

console.log("server has been started");
function App() {

 
  const [errors, setErrors] = useState([]);
  const [scrolling, setScrolling] = useState(false);

  const [darkMode, setDarkMode] = useState(false);
  const location = useLocation();

  const navigationType = useNavigationType();

  // useEffect(() => {
  //   if (ReactSession.get('darkMode')) {
  //     setDarkMode(true);
  //   }
  // }, []);

  useEffect(() => {
    if (navigationType !== 'POP' && !scrolling) {
      const timeoutId = setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, 300);
      return () => clearTimeout(timeoutId);
    }
  }, [location, navigationType]);

  function ScrollDelay() {
    const location = useLocation();
    const scrollPositionRef = useRef(0);
  
    useEffect(() => {
      const handlePopState = () => {
        scrollPositionRef.current = window.scrollY;
        setTimeout(() => {
          window.scrollTo(0, scrollPositionRef.current);
        }, 300);
      };
      window.addEventListener("popstate", handlePopState);
  
      return () => {
        window.removeEventListener("popstate", handlePopState);
      };
    }, [location]);
    return null;
  }

  return (
    <>

    <ScrollDelay/>
 <Header setDarkMode={setDarkMode} darkModes={darkMode} setErrors={setErrors} errors={errors} setScrolling={setScrolling}/>
      <Messages errors={errors} setErrors={setErrors}/>
      <Follow darkMode={darkMode} />
      <TransitionGroup component={null}>
       <CSSTransition key={location.key} classNames="fade" timeout={200}>
          <Routes location={location}>
            <Route path="/" element={<Indexes darkMode={darkMode} errors={errors} setErrors={setErrors}/>}/>
            <Route path="/Privatumo-politika" element={<Policy darkMode={darkMode} errors={errors} setErrors={setErrors}/>}/>
          </Routes>

        </CSSTransition><Footer />
      </TransitionGroup>
      
    </>
  );
}

const Root = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

render(<Root />, document.getElementById('root'));