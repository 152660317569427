import React, { useState, useEffect } from "react";
import "./follow.css";

function Follow({ darkMode }) {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [squares, setSquares] = useState([]);

  const calculateSquares = () => {
    const vw = window.innerWidth;
    const vh = window.innerHeight;

    const columns = Math.ceil(vw / 200);
    const rows = Math.ceil(vh / 200);

    const totalSquares = columns * rows;
    setSquares(Array(totalSquares).fill(null));
  };

  useEffect(() => {
    calculateSquares();
    window.addEventListener("resize", calculateSquares);

    return () => {
      window.removeEventListener("resize", calculateSquares);
    };
  }, []);

  const handleMouseMove = (event) => {
    setPosition({
      x: event.clientX,
      y: event.clientY,
    });
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div className="follow">
      <div className="followBackground"></div>
      <div className={`squares ${darkMode ? 'dark-mode' : ''}`}>
        <div
          className="circle"
          style={{
            transform: `translate(${position.x - 25}px, ${position.y - 25}px)`,
          }}
        ></div>
        {squares.map((_, index) => (
          <div key={index} className={`square ${darkMode ? 'dark-mode' : ''}`}></div>
        ))}
      </div>

    </div>
  );
};

export default Follow;
