import './footer.css'
import { NavLink } from 'react-router-dom';
// import logoImg from '../../assets/websiteicon.png';


function Footer() {

  return (
    <>
      <footer className="footer">
      <hr />
        <div className='container'>
<NavLink to="/">
          <img className='logoImg' src={process.env.PUBLIC_URL + '/assets/websiteicon.png'} alt="" />
</NavLink>
          <div>
            <NavLink to="/Privatumo-politika">Privatumo politika</NavLink>
            <p>info@instalika.eu</p>
            <p>+370 655 65525</p>
          </div>
        </div>

      </footer>
    </>
  );
}

export default Footer;
